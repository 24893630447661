
import { defineComponent, onMounted, ref } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { Form, Field, ErrorMessage } from "vee-validate";
import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import variables from "@/router/api";
import * as Yup from "yup";
import { useRouter } from "vue-router";
import { useStore } from "vuex";

export default defineComponent({
  name: "edit-personal-accident-house-house-insurance-claim-component",
  components: { Form, Field, ErrorMessage },
  props: {
    publicId: String
  },
  data() {
    return {
      canDoBusiness: false,
      hasMedicalReport: false,
      claimNature: "",
      lossDate: "",
      injurySustained: "",
      minimumDate: "",
      todaysDate: "",
      amount: "",
      medicalReport: "",
      descriptionOfIncident: "",
      acceptTerms: false,
      hospitalName: "",
      hospitalDoctorName: "",
      attendToOccupation: "",
      disableStartDate: "",
      disableEndDate: "",
      policies: [],
      policyDetails: [],
      itemPublicId: "",
      policy: "",
      role: "",
      route: "",
      policyItemRoute: ""
      // publicId: this.$route.params.publicId,
    };
  },
  setup(props) {
    const submitButton1 = ref<HTMLElement | null>(null);
    const router = useRouter();

    const submitForm = (values, { resetForm }) => {
      // console.log(values);
      if (submitButton1.value) {
        // Activate indicator
        submitButton1.value.setAttribute("data-kt-indicator", "on");

        setTimeout(() => {
          submitButton1.value?.removeAttribute("data-kt-indicator");

          // Send personal information update request
          if (JwtService.getToken()) {
            const form = document.forms[0];
            const formData = new FormData(form);

            ApiService.setHeader();
            ApiService.post(
              `${variables.CLAIMS_ROUTE}/${props.publicId}`,
              formData,
              {
                headers: {
                  "Content-Type": "multipart/form-data"
                }
              }
            )
              .then((response) => {
                variables.toastAlert(response.data.data.message, "success");
                resetForm();
                // Redirect back to claims history page
                router.push({
                  name: "client-claims-history"
                });
              })
              .catch(function (error) {
                console.log(error);
                variables.toastAlert(error.response.data.error, "error");
              });
          }
        }, 2000);
      }
    };

    const claimsValidator = Yup.object().shape({
      policy: Yup.mixed().required().label("Policy reference"),
      claimNature: Yup.mixed().required().label("Claim nature"),
      lossDate: Yup.date().required().label("Date and time of incident"),
      descriptionOfIncident: Yup.string()
        .required()
        .label("Full description of incident"),
      acceptTerms: Yup.boolean()
        .oneOf([true])
        .required()
        .label("Terms & conditions")
    });

    onMounted(() => {
      setCurrentPageBreadcrumbs("Edit Personal Accident", [
        "Claims",
        "Report Claims",
        "House Insurance Claim"
      ]);
    });

    return { submitButton1, claimsValidator, submitForm };
  },
  created() {
    const router = useRouter();
    const store = useStore();
    this.role = store.getters.currentUser.role;

    // Check if publicId is passed as a prop
    if (this.publicId == null) {
      router.go(-1);
    }

    // Get claim information route based on user roles
    if (this.role == variables.INDIVIDUAL_CLIENT_USER_ROLE) {
      this.route = `${variables.CLAIMS_ROUTE}/${this.publicId}`;
      this.policyItemRoute = `${variables.POLICIES_ROUTE}`;
    } else if (this.role == variables.BROKER_USER_ROLE) {
      // Broker role claim information route
      // this.route = `${variables.BROKER_CLIENTS_CLAIMS_ROUTE}/${this.publicId}`;
      this.route = `${variables.CLAIMS_ROUTE}/${this.publicId}`;
      this.policyItemRoute = `${variables.BROKER_CLIENT_POLICY_DETAILS_ROUTE}`;
    } else if (
      this.role == variables.ADMIN_USER_ROLE ||
      this.role == variables.SUPER_ADMIN_USER_ROLE
    ) {
      // Super Admin & Admin role claim information route
      this.route = `${variables.SUPERADMIN_CLIENT_CLAIMS_ROUTE}/${this.publicId}`;
      this.policyItemRoute = `${variables.SUPERADMIN_CLIENT_POLICY_DETAILS_ROUTE}`;
    }

    // Get the current date
    this.getCurrentDate();

    // Get the list of policies associated with this claim type
    this.getPolicies();

    // Get the associated claim information
    this.getClaimInformation();

    //Set page title
    document.title =
      "Edit Personal Accident House Insurance Claim | " +
      process.env.VUE_APP_TITLE;
  },
  methods: {
    getClaimInformation() {
      const router = useRouter();
      //Fetch individual vehicle policy information
      if (JwtService.getToken()) {
        ApiService.setHeader();
        ApiService.get(this.route)
          .then(({ data }) => {
            //Assign data to variables
            this.claimNature = data.data.metadata.claimNature;
            this.policy = data.data.metadata.policy;
            this.getPolicyInformation(data.data.metadata.policy);
            this.itemPublicId = data.data.metadata.itemPublicId;
            this.lossDate = data.data.metadata.lossDate;
            this.injurySustained = data.data.metadata.injurySustained;
            this.amount = data.data.metadata.amount;
            this.descriptionOfIncident =
              data.data.metadata.descriptionOfIncident;
            this.hospitalName = data.data.metadata.hospitalName;
            this.hospitalDoctorName = data.data.metadata.hospitalDoctorName;
            this.attendToOccupation = data.data.metadata.attendToOccupation;
            this.disableStartDate = data.data.metadata.disableStartDate;
            this.disableEndDate = data.data.metadata.disableEndDate;
            this.acceptTerms =
              data.data.metadata.acceptTerms == "on" ? true : false;
            if (data.data.metadata.attendToOccupation == "Yes") {
              this.canDoBusiness = true;
            } else {
              this.defaultValues();
            }
            if (data.data.documents != null) {
              this.hasMedicalReport = true;
            }
          })
          .catch(function (error) {
            variables.toastAlert(error.response.data.error, "error");
            console.log(error);
            router.go(-1);
          });
      }
    },
    getPolicies() {
      //Fetch client policies
      if (JwtService.getToken()) {
        ApiService.setHeader();
        ApiService.get(`${variables.POLICIES_ROUTE}/package/HOU/HPA`)
          .then(({ data }) => {
            //Assign data to props
            this.policies = data.data;
          })
          .catch(function (error) {
            variables.toastAlert(error.response.data.error, "error");
          });
      }
    },
    getPolicyInformation(value) {
      //Fetch individual personal accident claim information
      if (JwtService.getToken()) {
        ApiService.setHeader();
        ApiService.get(`${variables.POLICIES_ROUTE}/${value}/items`)
          .then(({ data }) => {
            //Assign data to variables
            this.policyDetails = data.data;
            this.minimumDate = data.data[0].policyStartDate;
            this.itemPublicId = data.data[0].publicId;
          })
          .catch(function (error) {
            variables.toastAlert(error.response.data.error, "error");
          });
      }
    },
    addCommaToNumber(event) {
      // Add comma to number value field
      const newValue = event.target.value
        .replace(/\D/g, "")
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      this.$nextTick(() => (event.target.value = newValue));
    },
    getAttendToOccupation(event) {
      this.defaultValues();

      const value = event.target.options[event.target.selectedIndex].value;

      // If the claim nature is accident
      if (value == "Yes") {
        this.canDoBusiness = true;
      } else {
        this.defaultValues();
      }
    },
    getCurrentDate() {
      const currentYear = new Date().getFullYear();
      this.minimumDate = currentYear + "-01-01";
      this.todaysDate = variables.currentDate();
    },
    defaultValues() {
      this.canDoBusiness = false;
    },
    addCommaToNumberString(amount) {
      return variables.addCommaToNumberString(amount);
    }
  }
});
