
import { defineComponent, onMounted } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import EditPersonalAccident from "@/components/reusable/claims/house-insurance/personal-accident/EditPersonalAccident.vue";

export default defineComponent({
  name: "edit-personal-accident-house-insurance-claims",
  components: { EditPersonalAccident },
  data() {
    return {
      publicId: this.$route.params.publicId
    };
  },
  setup() {
    onMounted(() => {
      setCurrentPageBreadcrumbs("Edit Personal Accident", [
        "Claims",
        "Report Claims",
        "House Insurance Claim"
      ]);
    });
  }
});
